<script>
import QuestionTooltip from "@/components/app/policyCancel/components/QuestionTooltip.vue";

export default {
    name: "OrantaPolicyCancel",
    components: {QuestionTooltip},
    model: {
        prop: 'dataForm',
        event: 'input',
    },
    props: {
        dataForm: {
            type: Object,
            default: function () {
                return {
                    email: null,
                    reason: null,
                }
            }
        }
    },
    watch: {
        dataForm: {
            handler: function (val) {
                this.$emit('is-fill-form', this.checkFormComplete(val));
            },
            deep: true
        }
    },
    methods: {
        checkFormComplete(val) {
            return val.email && val.reason;
        }
    }
}
</script>

<template>
    <div class="policy-cancel-custom">
        <question-tooltip>
            УВАГА! Обов’язково необхідно вказувати достовірну електронну пошту клієнта та причину ануляції договору.
        </question-tooltip>
        <el-row>
            <el-col class="title mb-4">
                Для подання запиту на ануляцію в СК Оранта необхідно вказати електронну пошту страхувальника та
                повідомити про причину ануляції
            </el-col>
            <el-col class="mb-4">
                <el-input placeholder="Наприклад, klient@ukasko.com.ua" type="email"
                          v-model="dataForm.email"></el-input>
            </el-col>
            <el-col class="mb-4">
                <el-input placeholder="Наприклад, невірна марка авто" v-model="dataForm.reason"></el-input>
            </el-col>
        </el-row>
    </div>
</template>

<style scoped>

</style>
