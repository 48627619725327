<script>
import QuestionTooltip from "@/components/app/policyCancel/components/QuestionTooltip.vue";

export default {
    name: "KnajzaPolicyCancel",
    components: {QuestionTooltip},
    model: {
        prop: 'dataForm',
        event: 'input',
    },
    props: {
        dataForm: {
            type: Object,
            default: function () {
                return {
                    numberPolicy: null,
                }
            }
        }
    },
    watch: {
        dataForm: {
            handler: function (val) {
                this.$emit('is-fill-form', val.numberPolicy);
            },
            deep: true
        }
    }
}
</script>

<template>
    <div class="policy-cancel-custom">
        <question-tooltip>
            УВАГА! За умовами СК Княжа, запит на ануляцію можливий лише за умови укладання нового договору цієї ж страхової компанії на той самий транспортний засіб або страхувальника.
            <br>
            У разі незгоди з цими умовами та бажання самостійно анулювати договір, клієнт може звернутися безпосередньо до СК Княжа за допомогою гарячої лінії.
        </question-tooltip>
        <el-row>
            <el-col class="title mb-4">
                Для подання запиту на ануляцію в СК Княжа необхідно оформити новий поліс від цієї ж страхової компанії на цей же автомобіль або страхувальника та написати його номер нижче.
            </el-col>
            <el-col class="mb-4">
                <el-input placeholder="Наприклад, 200058325"
                          v-model="dataForm.numberPolicy"></el-input>
            </el-col>
        </el-row>
    </div>
</template>

<style scoped>

</style>
