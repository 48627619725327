import StandardPolicyCancel from "@/components/app/policyCancel/cancellations/StandardPolicyCancel.vue";
import OrantaPolicyCancel from "@/components/app/policyCancel/cancellations/OrantaPolicyCancel.vue";
import KnajzaPolicyCancel from "@/components/app/policyCancel/cancellations/KnajzaPolicyCancel.vue";
import StatementPolicyCancel from "@/components/app/policyCancel/cancellations/StatementPolicyCancel.vue";
import VusoPolicyCancel from "@/components/app/policyCancel/cancellations/VusoPolicyCancel.vue";

export default {
    StandardPolicyCancel,
    OrantaPolicyCancel,
    KnajzaPolicyCancel,
    StatementPolicyCancel,
    VusoPolicyCancel,
};
