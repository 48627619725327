<script>
export default {
    name: "StandardPolicyCancel",
    mounted() {
        this.$emit('is-fill-form', true)
    }
}
</script>

<template>
<div>

</div>
</template>

<style scoped>

</style>
