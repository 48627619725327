<script>
export default {
    name: "QuestionTooltip"
}
</script>

<template>
    <div class="question">
        <el-tooltip class="item" effect="dark">
            <div slot="content" class="text-center">
                <slot></slot>
            </div>
            <i class="icon question"></i>
        </el-tooltip>
    </div>
</template>

<style scoped>
.question {
    position: absolute;
    top: 10px;
    right: 10px;
    .icon.question {
        width: 20px;
        height: 20px;
    }
}
</style>
