<script>
import QuestionTooltip from "@/components/app/policyCancel/components/QuestionTooltip.vue";
import FileInput from "@/components/app/policyCancel/components/FileInput.vue";
import Integer from "vuelidate/lib/validators/integer";

export default {
    name: "StatementPolicyCancel",
    components: {FileInput, QuestionTooltip},
    model: {
        prop: 'dataForm',
        event: 'input',
    },
    props: {
        dataForm: {
            type: Object,
            default: function () {
                return {
                    file: null,
                }
            }
        },
        companyName: {
            type: String,
            default: function () {
                return '';
            },
        },
        moduleId: {
            type: Integer,
            default: function () {
                return '';
            }
        }
    },
    watch: {
        dataForm: {
            handler: function (val) {
                this.$emit('is-fill-form', val.file);
            },
            deep: true
        }
    },
}
</script>

<template>
    <div class="policy-cancel-custom">
        <question-tooltip>
            УВАГА! Заява має бути від імені клієнта,  з вірними датами та повністю заповнена, інакше страхові компанії можуть відмовити в ануляції договору.
        </question-tooltip>
        <el-row>
            <el-col class="title mb-4">
                Для подання запиту на ануляцію в {{ companyName }} необхідно заповнити та прикріпити заяву на ануляцію договору.
            </el-col>
            <el-col class="text-center mb-4">
                <u><b><el-link :href="'/api/download-cancellation-statement-template/' + moduleId" target="_blank" :underline="false">Завантажити шаблон заяви</el-link></b></u>
            </el-col>
            <el-col class="mb-4">
                <file-input v-model="dataForm.file"></file-input>
            </el-col>
        </el-row>
    </div>
</template>

<style scoped>

</style>
