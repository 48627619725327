<script>
import PolicyCancelSuccessDialog from "@/components/app/policyCancel/PolicyCancelSuccessDialog.vue";
import cancellationTypes from './cancellations'
import {createCancellation} from "@/api/cancellations";
import displaySettings from "@/mixin/displaySettings";

export default {
    name: "PolicyCancelDialog",
    components: {PolicyCancelSuccessDialog, ...cancellationTypes},
    props: ['order', 'dialogVisible'],
    mixins: [displaySettings],
    data() {
        return {
            showSuccessDialog: false,
            isSubmitDisabled: true,
            dataForm: {},
            module: null,
        }
    },
    watch: {
        dialogVisible(val) {
            if(val) {
                this.getModule(this.order.moduleId)
            }
        }
    },
    computed: {
        cancellationType() {
            if (this.module) {
                switch (this.module.name) {
                    case 'vuso': //10: //vuso
                        return 'VusoPolicyCancel';
                    case 'utsk'://17://utsk
                    case 'usg': //9: //usg
                    case 'brock': //12://brock
                    case 'euroins'://20: //euroins
                    case 'etc'://24: //etc
                        return 'StandardPolicyCancel';
										case 'tas'://11: //tas
												if (this.order.insurant.customerTypeId === 1) {
													return 'StandardPolicyCancel';
												} else {
													return 'StatementPolicyCancel';
												}
                    case 'knajza'://15: //knajza
                        return 'KnajzaPolicyCancel';
                    case 'oranta'://18: //oranta
                        return 'OrantaPolicyCancel';
                    // case 23:
                    //     return 'OrantaPolicyCancel';
                    default:
                        return 'StatementPolicyCancel';
                }
            } else {
                return null;
            }
        }
    },
    methods: {
        getModule(id) {
            //TODO axios move to api directory
            axios.get('/api/modules/' + id).then(response => {
                this.module = response.data;
            })
        },
        policyCancel() {
            createCancellation(this.order.id, this.dataForm).then(response => {
                this.$emit('update:dialogVisible', false);
                this.showSuccessDialog = true;
            })
        },
        closeModal() {
            this.dataForm = {};
            this.$emit('update:dialogVisible', false);
        },
        checkFormComplete(event) {
            this.isSubmitDisabled = !event
        }
    },
}
</script>

<template>
    <div class="policy-cancel">
        <el-dialog
            :width="isMobile ? '100%' : '20%'"
            :visible.sync="dialogVisible"
            center
            top="30vh"
            custom-class="p-4"
            :before-close="closeModal"
            :show-close="cancellationType === 'StandardPolicyCancel'"
            :destroy-on-close="true"
            ref="dialog"
        >
            <div v-loading="!!!cancellationType">
                <component
                    v-if="cancellationType"
                    v-model="dataForm"
                    :is="cancellationType"
                    :company-name="order.companyInfo.publicName"
                    :module-id="order.moduleId"
                    @is-fill-form="checkFormComplete"
                />
            </div>
            <div slot="footer">
                <el-row>
                    <el-col>
                        <div class="title mb-4">Ви впевнені, що бажаєте подати поліс на ануляцію?</div>
                    </el-col>
                    <el-col class="mb-4">
                        <el-button
                            :disabled="isSubmitDisabled"
                            type="primary"
                            class="w-100"
                            @click="policyCancel"
                        >
                            Так, подати поліс на ануляцію.
                        </el-button>
                    </el-col>
                    <el-col>
                        <el-button class="cancel w-100" @click="closeModal">Ні, я хочу залишити поліс діючим.
                        </el-button>
                    </el-col>
                </el-row>
            </div>
        </el-dialog>
        <policy-cancel-success-dialog :dialog-visible.sync="showSuccessDialog"/>
    </div>
</template>

<style lang="scss">
@import "./styles/policy-cancel.scss";
</style>
