<script>
import displaySettings from "@/mixin/displaySettings";

export default {
    name: "PolicyCancelSuccessDialog",
    props: ['dialogVisible'],
    mixins: [displaySettings],
		methods: {
			reloadPage() {
				location.reload(false)
			}
		},
}
</script>

<template>
    <div class="policy-cancel">
        <el-dialog
            :width="isMobile ? '100%' : '20%'"
            :visible.sync="dialogVisible"
            center
            top="30vh"
            custom-class="p-4"
        >
            <div slot="title">
                <div class="title mb-2">Заявка на ануляцію полісу отримана, очікуйте зміни статусу в історії замовлень.</div>
            </div>
            <div class="description">
                Після припинення дії полісу кошти буде повернуто на гаманець.
            </div>
            <div slot="footer">
                <el-row>
<!--									@click="$emit('update:dialogVisible', false)"-->

                    <el-col>
                        <el-button
													type="primary"
													class="w-100"
													@click="reloadPage"
												>Продовжити</el-button>
                    </el-col>
                </el-row>
            </div>
        </el-dialog>
    </div>
</template>

<style lang="scss" scoped>

</style>
